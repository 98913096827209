







import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
// import FsNavaccount from '@/components/fsBusiness/mobile/account/FsNavaccount.vue';
// import MyProfile from '@/components/fsBusiness/mobile/account/FsMemberInfo.vue';
// import MyCoupon from '@/components/fsBusiness/mobile/account/FsMyCoupon.vue';
// import MyFavorite from '@/components/fsBusiness/mobile/account/FsMyFavorite.vue';
// import Notification from '@/components/fsBusiness/mobile/account/FsNotification.vue';
// import OrderList from '@/components/fsBusiness/mobile/account/FsOrderList.vue';
// import DeliveryAddress from '@/components/fsBusiness/mobile/account/FsDeliveryAddress.vue';
// import Points from '@/components/fsBusiness/mobile/account/FsPoints.vue';
@Component({
  components: {
    FsNavaccount: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsNavaccount.vue'),
    MyProfile: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsMemberInfo.vue'),
    MyCoupon: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsMyCoupon.vue'),
    MyFavorite: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsMyFavorite.vue'),
    Notification: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsNotification.vue'),
    OrderList: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsOrderList.vue'),
    DeliveryAddress: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsDeliveryAddress.vue'),
    Points: () => import(/* webpackChunkName: "mobileAccount" */'@/components/fsBusiness/mobile/account/FsPoints.vue')
  }
})
export default class InsMemberCentral extends Vue {
  private to:string = 'MyProfile';
  To (path) {
    if (this.to === path) return;
    this.$ShowLayer();
    this.to = path;
  }
  created () {
    if (this.$route.query.to) { this.To(this.$route.query.to); }
  }
  @Watch('$route', { deep: true })
  onRouterChange () {
    if (this.$route.query.to) { this.To(this.$route.query.to); } else { this.To('MyProfile'); }
  }
}
