import { render, staticRenderFns } from "./FsPoints.vue?vue&type=template&id=305a94fa&scoped=true&"
import script from "./FsPoints.vue?vue&type=script&lang=ts&"
export * from "./FsPoints.vue?vue&type=script&lang=ts&"
import style0 from "./FsPoints.vue?vue&type=style&index=0&id=305a94fa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305a94fa",
  null
  
)

export default component.exports