



















import { Component, Prop, Vue } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
@Component
export default class accountHeader extends Vue {
   MemberName:string='';
   TotalPoints:string='';
   // 获取会员总积分
   getMemberTotal () {
     this.$Api.pointsApi.GetMemberTotalPointsInfo().then((result) => {
       console.log(result, '会员');
       this.TotalPoints = result.data.TotalPoints;
       this.MemberName = result.data.MemberName;
     });
   }
   mounted () {
     this.getMemberTotal();
   }
}
